<template>
    <div id="content">

        <div id="processList" class="card" v-if="!selected_data">
            
            <h3 class="card_title">Prozess Auswählen</h3>
            <div class="padding">
                <div class="flex">
                    <p v-for="(pro, p) in processes_available" @click="newProcess(pro)" class="dark radius marginLeft pointer padding_small fitContent" :key="p">{{dict(pro)}}</p>
                </div>
                <!-- <searchlist @select="newProcess($event)" class="marginAuto" :placeholder="'Prozess Wählen'" :values="processes_available"></searchlist> -->
                <!-- <img class="invert pointer" @click="process_new = false" src="https://dashboard.pixelprime.de/assets/icons/light/decline.svg" alt=""> -->
            </div>
        </div>

        <div id="partSelect" class="card" v-if="selected_data && !templateLoaded">
            <h3 class="card_title">Teil auswählen</h3>
            <div class="padding">
                <preview-3D @selected="template_id = $event"></preview-3D>
                <!-- <div class="flex">
                    <p v-for="(pro, p) in processes_available" @click="newProcess(pro)" class="dark radius marginLeft pointer padding_small fitContent" :key="p">{{dict(pro)}}</p>
                </div> -->
                <!-- <searchlist @select="newProcess($event)" class="marginAuto" :placeholder="'Prozess Wählen'" :values="processes_available"></searchlist> -->
                <!-- <img class="invert pointer" @click="process_new = false" src="https://dashboard.pixelprime.de/assets/icons/light/decline.svg" alt=""> -->
            </div>
        </div>

        <div id="processList" class="card" v-if="selected_data && templateLoaded">
            <div class="card_title">
                <h3>Prozess definieren</h3>
                <img id="deleteButton" @click="deleteProcess(0)" src="https://dashboard.pixelprime.de/assets/icons/decline.svg" class="pointer" title="Prozess löschen">
            </div>
            <div>
                <div id="viewerBox">
                    <div id="chainContainer" @contextmenu="preventDefault">
                        <div id="chain" class="padding">
                            <b>{{dict(selected_data.name)}}</b>
                            <div v-for="(subProcess, p) in selected_data.processChain" :key="p" class="indent">
                                <div @click="subProcess_select(subProcess)" :class="{blue: subProcess_selected == subProcess}" class="dark padding_small radius fitContent pointer flex">
                                    <indicator :condition="verify.processes[process_selected].subProcesses[subProcess]" :required="selected_data.subProcesses[subProcess].required"></indicator>
                                    <p style="white-space: nowrap;">{{dict(subProcess)}}</p>
                                </div>
                                <!-- Check for Nested Processes: -->
                                <div v-for="(nested, n) in selected_data.subProcesses[subProcess].nestedProcesses" :key="n">
                                    <div @click="subProcess_select(nested)" :class="{blue: subProcess_selected == nested}" v-if="checkNestedCondition(subProcess, nested)" class="indent dark pointer lightdark radius padding_small fitContent flex">
                                        <indicator :condition="verify.processes[process_selected].subProcesses[nested]" :required="selected_data.subProcesses[nested].required"></indicator>
                                        <p style="white-space: nowrap;">{{dict(nested)}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="propertyContainer" @contextmenu="preventDefault">
                        <div v-show="subProcess_selected" id="properties">
                            <!-- <b>{{dict(subProcess_selected)}}</b> -->
                            <component v-if="components(subProcess_selected+'_'+selected_data.name)" :is="subProcess_selected+'_'+selected_data.name"></component>
                        </div>
                    </div>
                    <div>
                        <viewer></viewer>
                    </div>
                    <div id="debug" class="padding" v-if="subProcess_selected == 'step'">
                        <b>Face:</b>
                        <div class="flex indent" v-for="(prop, p) in debug_3d.faceInfo" :key="p+'_f'">
                            <p style="width: 8em">{{p}}</p>
                            <p>{{prop}}</p>
                        </div>
                        <b>Edges:</b>
                        <div class="indent textspacing" v-for="(edge, e) in debug_3d.edges" :key="e">
                            <div class="flex" v-for="(prop, p) in edge[0]" :key="p">
                                <p style="width: 8em">{{p}}</p>
                                <p>{{prop}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div id="definitions">
                    <component v-if="components(subProcess_selected+'_'+selected_data.name)" :is="subProcess_selected+'_'+selected_data.name"></component>
                </div> -->



                <!-- <div class="box">
                    <div @click="process_select(p)" :class="{blue: process_selected == p}" class="padding_small radius dark fitContent pointer" :key="p" v-for="(process, p) in processes_created">
                        <indicator :condition="verify.processes[p].met" required="true"></indicator>
                        {{dict(process.name)}}
                        <img @click="deleteProcess(p)" style="height: 1.25em" src="https://dashboard.pixelprime.de/assets/icons/delete.svg" alt="">
                    </div>
                    <div id="processNew" v-if="process_new">
                        <searchlist @select="newProcess($event)" class="marginAuto" :placeholder="'Prozess Wählen'" :values="processes_available"></searchlist>
                        <img class="invert pointer" @click="process_new = false" src="https://dashboard.pixelprime.de/assets/icons/light/decline.svg" alt="">
                    </div>
                    <div v-else-if="!processes_created.length" class="padding_small dark radius flex fitContent pointer" @click="process_new = true">
                        <img src="https://dashboard.pixelprime.de/assets/icons/add.svg" alt="">
                        <p class="marginAuto spacingright"><b>Neuer Prozess</b></p>
                    </div>
                    <p class="padding" v-if="newProcess_load">Lade neuen Prozess...</p>
                </div> -->
            </div>
            <!-- <div id="chain" class="padding" v-if="processes_created.length">
                <b>{{dict(selected_data.name)}}</b>
                <div v-for="(subProcess, p) in selected_data.processChain" :key="p" class="indent">
                    <div @click="subProcess_select(subProcess)" :class="{blue: subProcess_selected == subProcess}" class="dark padding_small radius fitContent pointer flex">
                        <indicator :condition="verify.processes[process_selected].subProcesses[subProcess]" :required="selected_data.subProcesses[subProcess].required"></indicator>
                        <p>{{dict(subProcess)}}</p>
                    </div>
                    Check for Nested Processes:
                    <div v-for="(nested, n) in selected_data.subProcesses[subProcess].nestedProcesses" :key="n">
                        <div @click="subProcess_select(nested)" :class="{blue: subProcess_selected == nested}" v-if="checkNestedCondition(subProcess, nested)" class="indent dark pointer lightdark radius padding_small fitContent flex">
                            <indicator :condition="verify.processes[process_selected].subProcesses[nested]" :required="selected_data.subProcesses[nested].required"></indicator>
                            <p style="white-space: nowrap;">{{dict(nested)}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <component v-if="components(subProcess_selected+'_'+selected_data.name)" :is="subProcess_selected+'_'+selected_data.name"></component> -->
        </div>
    </div>
            <!-- <div class="card" style="margin-bottom: 1em">
                <h3 class="card_title" v-if="!information.part_type">Fertigungsart bestimmen</h3>
                <div class="padding cardBox"  v-if="!information.part_type">
                    <div class="box" @click="$store.commit('information_set', ['part_type', 'machining'])">
                        <p><b>Zerspanung</b></p>
                        <div class="indent">
                            <p>Drehen</p>
                            <p>Fräsen</p>
                        </div>
                    </div>                
                    <div class="box" @click="$store.commit('information_set', ['part_type', 'grinding'])">
                        <p><b>Schleifen</b></p>
                        <div class="indent">
                            <p></p>
                        </div>
                    </div>                    
                    <div class="box" @click="$store.commit('information_set', ['part_type', 'additive'])">
                        <p><b>Additiv</b></p>
                        <div class="indent">
                            <p>3D Druck</p>
                            <p>SLS</p>
                        </div>
                    </div>                    
                </div>
                <p v-else class="white radius padding_small pointer" @click="$store.commit('information_set', ['part_type', 0])">Andere Fertigungsart</p>
            </div> -->


        <!-- </div>
        
        <div id="processOptions">
        </div>        

    </div> -->
</template>

<script>
    import Vue from "vue";
    import apiCall from '@/apiCall'
    import Preview3D from '../../components/three/Preview3D.vue';

    export default {
    components: { Preview3D },
        name: "Processes",
        data() {
            return {
                process_new: 0,
                newProcess_load: false,
                template_id: -1,
            }
        },
        created() {
            this.processes_getAvailable()
        },
        mounted() {
            // this.$store.commit("changeSubprocessValue", ["dimensions", "length", Math.round(dimensions.length)]);
            // this.$store.commit("changeSubprocessValue", ["dimensions", "diameter", Math.round(dimensions.diameter)]);
            // this.$store.commit("changeSubprocessValue", ["contour", "selected", volumeRatio]);
            // this.$store.commit("changeSubprocessValue", ["step", "volume_raw", 1000]);
            // this.$store.commit("changeSubprocessValue", ["step", "dimensions", dimensions]);
            // let processes = document.createElement('script')
            // processes.setAttribute('src', 'https://development.primecalc.de/processes.js')
            // document.head.appendChild(processes)
        },
        computed: {
            information() {
                return this.$store.getters.information
            },
            processes_available() {
                return this.$store.state.processes_available
            },
            selected_data() {
                return this.$store.state.calculation.processes[this.process_selected] || 0
            },
            process_selected() {
                return 0// this.$store.state.process_selected
            },
            subProcess_selected() {
                return this.$store.state.subProcess_selected
            },
            verify() {
                return this.$store.getters.verify
            },
            templateLoaded() {
                return this.$store.getters.subProcess("template").defined
            },
            template_information() {
                if(!this.$store.getters.previewInformation) return 0
                let info = 0
                this.$store.getters.previewInformation.map(templ => {if(templ.id == this.template_id) info = templ})
                return info
            },
            debug_3d() {
                return this.$store.getters.debug_3d
            }
        },
        methods: {        
            preventDefault(event) {
                console.log(event);
                // event.preventDefault()
            },
            dict(c) {  
                if(!this.$store.state.dict) return c
                return this.$store.state.dict[c] || c
            },
            components(comp) {
                return comp in Vue.options.components
            },
            processes_getAvailable() {
                apiCall({
                    method: "processes_getAvailable"
                }).then(response => {
                    if (response.statusCode == 200) {
                        this.$store.commit("setValue", ["processes_available", response.body])
                    }
                    else {
                        let result = ["turning", "milling"]
                        this.$store.commit("setValue", ["processes_available", result])
                    }
                })
            },
            subProcess_select(p) {
                if(!this.$store.getters.subProcess(p)) this.$store.commit("addSubprocess", [p])
                this.$store.state.subProcess_selected = p
                
                setTimeout(() => {
                    this.$store.getters.viewer.canvas_scale()
                }, 50);
                this.$store.getters.viewer.resetMaterials()
                delete this.$store.getters.viewer.onHover
                delete this.$store.getters.viewer.onClick
            },
            newProcess(type) {
                this.deleteProcess(this.process_selected)
                this.process_new = false
                this.newProcess_load = true

                apiCall({
                    method: "processes_getTemplate",
                    process: type
                }).then(response => {
                    this.newProcess_load = false
                    if (response.statusCode == 200) {
                        this.$store.commit("addNewProcess", response.body)
                        // Vue.delete(state.saves, id)
                    }
                })

            },
            deleteProcess(p) {
                this.$store.commit("deleteProcess", p)
            },
            checkNestedCondition(subprocess, nested, p = 0) {
                let parent = this.$store.state.calculation.processes[p].subProcesses[subprocess]
                nested = this.$store.state.calculation.processes[p].subProcesses[nested]
                let conditionsMet = true
                for (const cond in nested.condition) {
                    if (parent.values && parent.values[cond].value != nested.condition[cond]) {
                        conditionsMet = false
                    }
                }
                return conditionsMet
            },
        }
    }
</script>


<style scoped>
    #partSeelct{
        width: -webkit-fill-available;
    }

    #viewerBox{
        display: grid;
        grid-template-columns: 0em 0em 1fr;
    }
    #chainContainer{
        z-index: 10;
    }
    #chain{
        grid-gap: 4px; 
        display: grid;
        width: fit-content;
        background-color: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(10px);
        transform: translateY(-1px);
        border-radius: 0 0 4px 0;
    }
    #propertyContainer{
        width: fit-content;
        height: fit-content;
        transform: translateX(200px);
    }

    #debug{
        grid-gap: 4px; 
        /* display: grid; */
        width: fit-content;
        background-color: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(10px);
        border-radius: 4px 0 0 4px;

        position: absolute;
        right: 0;
        bottom: 0;
        max-height: 70%;
        overflow-y: scroll;
    }

    /* .lightdark{background-color: rgb(250, 250, 250);} */
    .lightdark.indent{margin-left: 10px; margin-top: 2px;}
    .cardBox{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 1em;

    }
    .cardBox>div{
        transition: .25s;
        background-color: #f5f5f5;
        padding: 1em;
        cursor: pointer;
    }
    .cardBox>div:hover{
        background-color: #eef6ff;
    }
    /* #content{
        display: grid;   
        grid-template-columns: auto 5fr;
        grid-template-rows: auto;
        grid-template-areas: "list data" "list data";
    } */
    #processNew{
        display: grid;
        grid-template-columns: 1fr 2em;
    }
    #processList{
        grid-area: list;
        width: 100%;
    }
    #processOptions{
        grid-area: data;
    }

    #deleteButton{
        width: 2em;
        height: 2em;
        position: absolute;
        right: 0.25em;
        top: 0.25em;
    }


</style>





